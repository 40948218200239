import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { FaReact, FaBootstrap, FaHtml5 } from 'react-icons/fa';
import { SiTailwindcss, SiCss3 } from 'react-icons/si';
import { TbBrandCSharp, TbBrandMysql } from 'react-icons/tb';

import { DiJavascript1 } from 'react-icons/di';
import MeEncontre from '../layout/MeEncontre';

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: [
      'Desenvolvedor Web',
      'Desenvolvedor FrontEnd',
      'Desenvolvedor FullStack',
    ],
    loop: true,
    typeSpeed: 30,
    deleteSpeed: 20,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-2/3 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className="font-3xl font-normal">BEM VINDO!</h4>
        <h1 className="text-4xl lg:text-6xl font-bold">
          Olá, Eu sou
          <br />
          <samp className="text-designColor capitalize">Pablo Santos</samp>
        </h1>
        <h2 className="text-2xl lg:text-3xl font-bold text-white">
          <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#0ae357"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Profissional com mais de 6 anos de experiência no mercado, atuando
          como suporte técnico e programador em desenvolvimento web, com domínio
          de{' '}
          <strong>
            <i>HTML, CSS, SQL, JavaScript e C#</i>
          </strong>
          . Participei da reformulação e migração de um site corporativo,
          substituindo plataformas pré-moldadas por uma solução customizada.
          Durante minha formação em Engenharia de Software, desenvolvi projetos
          como um sistema de Marketplace e gestão de eventos, utilizando
          <strong>
            <i>ASP.NET Core, Entity Framework, MySQL e Angular.js</i>
          </strong>
          , com hospedagem na{' '}
          <strong>
            <i>AWS</i>
          </strong>{' '}
          e versionamento no{' '}
          <strong>
            <i>GitHub</i>
          </strong>
          .
        </p>

        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Além de competências técnicas, destaco meu{' '}
          <strong>pensamento analítico</strong>, adaptabilidade a novos
          contextos e desafios, pensamento inovador na criação de soluções e
          compromisso com o{' '}
          <strong>aprendizado contínuo (lifelong learning)</strong>. Valorizo o{' '}
          <strong>trabalho em equipe</strong>, a <strong>proatividade</strong> e
          a busca por impacto positivo em projetos inovadores.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-8 justify-between">
        <div className="order-2 xl:order-1">
          <h2 className="text-base font-titleFont mb-4">ME ENCONTRE EM</h2>
          <MeEncontre />
        </div>
        <div className="order-1 xl:order-2">
          <h2 className="text-base uppercase font-titleFont mb-4">
            MELHORES SKILLS
          </h2>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <span className="bannerIcon">
                <FaReact />
              </span>
              <span className="bannerIcon">
                <FaBootstrap />
              </span>
              <span className="bannerIcon">
                <SiTailwindcss />
              </span>
              <span className="bannerIcon">
                <TbBrandMysql />
              </span>
            </div>
            <div className="flex gap-4">
              <span className="bannerIcon">
                <DiJavascript1 />
              </span>
              <span className="bannerIcon">
                <FaHtml5 />
              </span>
              <span className="bannerIcon">
                <SiCss3 />
              </span>
              <span className="bannerIcon">
                <TbBrandCSharp />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBanner;
