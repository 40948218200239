// imagens
import projectMemory from '../assets/images/projects/MemoryPotter.jpg';
import projectCalc from '../assets/images/projects/calculadora.jpg';
import projectTerm from '../assets/images/projects/termometro.jpg';
// import projectLais from '../assets/images/projects/lais.jpg';
import projectOrigens from '../assets/images/projects/Origens.jpg';
import projectMUC from '../assets/images/projects/MUC.jpg';
import projectBruno from '../assets/images/projects/Bruno.jpg';
import projectSistema from '../assets/images/projects/sistema.jpg';

export const project = [
  {
    _id: 1000,
    title: 'Origens da terra',
    desc: 'Sistema criado em C# e angular, para auxiliar a venda e divulgação de produtos naturais, realizada por produtores locais e micro produtores',
    linkSite: 'https://origensdaterra.com.br',
    src: projectOrigens,
    extraText: 'Backend pausado na AWS',
  },
  {
    _id: 1006,
    title: 'Psicólogo Bruno Carlos',
    desc: 'Site criado para apresentar os serviços oferecidos pelo cliente e divulgar seu canal no youtube.',
    linkSite: 'https://psicologobrunocarlos.com.br/',
    src: projectBruno,
  },
  {
    _id: 1009,
    title: 'Método Última Chance',
    desc: 'Site para apresentação de um curso de relacionamentos',
    linkGit: 'https://github.com/pablo-santos21/metodouc',
    linkSite: 'https://metodouc.vercel.app/',
    src: projectMUC,
  },
  {
    _id: 1004,
    title: 'Gerenciamento de eventos',
    desc: 'Sistema criado em React e Node js, onde foi criado uma API para gerenciar todos os eventos que foram marcados em um salçao de festas.',
    linkGit: 'https://github.com/pablo-santos21/front-controle-evento',
    linkSite: 'https://projeto-evento-gv-psantos.netlify.app/',
    src: projectSistema,
  },
  {
    _id: 1001,
    title: 'Memory Potter',
    desc: 'Projeto pessoal, de criação de um jogo da memória, de um dos meus personagens favoritos, onde foram acrescentados alguns desafios',
    linkGit: 'https://github.com/pablo-santos21/memory-game',
    linkSite: 'https://projeto-obliviate.netlify.app/',
    src: projectMemory,
  },
  {
    _id: 1003,
    title: 'Termômetro',
    desc: 'Sistema criado utilizando uma API externa, do site, openweathermap, onde mostra a temperatura do local no exato momento da requisição.',
    linkGit: 'https://github.com/pablo-santos21/thermometer',
    linkSite: 'https://projeto-thermometer-psantos.netlify.app/',
    src: projectTerm,
  },
  {
    _id: 1005,
    title: 'Calculadora',
    desc: 'Projeto criado para colocar em pratica o que foi aprendido durante um curso realizado, ao final foi criado essa calculadora com design clean.',
    linkGit: 'https://github.com/pablo-santos21/calculadora',
    linkSite: 'https://projeto-calc-psantos.netlify.app/',
    src: projectCalc,
  },
];
