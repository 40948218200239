import { React, useEffect } from 'react';
import Titulo from '../layout/Titulo';
import { project } from '../../constants/projetos';
import { BsGithub } from 'react-icons/bs';
import { FaGlobe } from 'react-icons/fa';
import NavbarInterno from '../navbar/NavbarInterno';
import FooterInterno from '../footer/FooterInterno';
import Copy from '../footer/Copy';

import ReactGA from 'react-ga';

// Imagens

const Portifolio = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavbarInterno />
      <section
        id="projetos"
        className="w-full py-20 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Titulo title="Conheça alguns dos meus Projetos" desc="Projetos" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-14 pb-8">
          {project.map(
            ({ _id, title, desc, linkGit, linkSite, src, extraText }) => (
              <div
                className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000"
                key={_id}
              >
                <div className="w-full h-[80%] overflow-hidden rounded-lg">
                  <img
                    className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
                    src={src}
                    alt={src}
                  />
                </div>
                <div className="w-full mt-5 flex flex-col gap-6">
                  {extraText && (
                    <p className="text-sm mt-2 text-red-400 italic text-center">
                      {extraText}
                    </p>
                  )}
                  <div>
                    <div className="flex items-center justify-between">
                      <h3 className="text-base uppercase text-designColor font-normal">
                        {title}
                      </h3>
                      <div className="flex gap-2">
                        <a
                          href={linkGit}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer">
                            <BsGithub />
                          </span>
                        </a>
                        <a
                          href={linkSite}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer">
                            <FaGlobe />
                          </span>
                        </a>
                      </div>
                    </div>
                    <p className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300">
                      {desc}
                    </p>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </section>
      <FooterInterno />
      <Copy />
    </div>
  );
};

export default Portifolio;
